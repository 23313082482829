import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Logo } from "../../assets/svg/Logo";
import "./PaymentPlan.css";
import { PaymentCompletedIcon } from "../../assets/svg/PaymentConmpleted";
import Button from "../../components/Button";
import { APP_ROUTES } from "../../utils/app-routes";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const PaymentPlanSuccess = () => {
  const navigate = useNavigate();
  const handleContinue = () => {
    navigate(APP_ROUTES.uploadProfileImage);
  };

  return (
    <>
      <div className="payment-container">
        <div className="content-title">
          <Logo />
          <div
            className="m-t-64"
            style={{ width: "592px", height: "447px", background: "#1F1A2B" }}
          >
            <div className=" align-center  flex-column">
              <div className="payment-success-icon-wrapper align-center justify-center m-t-64">
                <PaymentCompletedIcon />
              </div>
              <span className="white boldL m-t-24">Payment sucessful!</span>
              <div className="w-50 align-center m-t-16">
                <span className="gray-200 regularS text-align-center padding-h-8">
                  Click the conntinue button below and you will be redirected to
                  the onboarding process
                </span>
              </div>
              <Button
                onClick={handleContinue}
                className="primary-button w-80 black m-t-24"
                height={"56px"}
                variety="filled"
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentPlanSuccess;
