import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import "react-select-search/style.css";
import { TrashIcon } from "../../assets/svg/TrashIcon";
import Button from "../Button";
import FormSelect from "../Select/FormSelect";
import { ChevronDownIcon } from "@radix-ui/react-icons";

export const FormProject = ({
  isLastTaskComplete,
  isEdit,
  updateLoading,
  createLoading,
  setModal,
  selectedData,
  selectedCategory,
  onSubmit,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      title: isEdit ? selectedData?.title || selectedData?.name : "",
      description: isEdit ? selectedData?.description : "",
      // category: isEdit ? selectedData?.category._id : "",
      tasksList: isEdit ? selectedData?.tasksList || selectedData?.tasks : [],
    },
    // resolver: yupResolver(),
  });

  const [visibleTasks, setVisibleTasks] = useState({});

  const { fields, remove, append } = useFieldArray({
    control,
    name: "tasksList",
  });

  const toggleTaskVisibility = (index) => {
    setVisibleTasks((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  useEffect(() => {
    if (fields?.length > 0) {
      setVisibleTasks({ 0: true });
    }
  }, [fields]);

  return (
    <form
      className="p-12 p-relative"
      onSubmit={handleSubmit(onSubmit)}
      style={{ marginBottom: 50, paddingLeft: 32 }}
    >
      <div className="pb-10 max-h-svh overflow-auto">
        <div>
          <label className="create-label regularM">Title</label>
          <Controller
            name="title"
            control={control}
            render={({ field }) => (
              <input
                className="m-t-8 quick-add-task-container create-project-input"
                style={{ background: "var(--App-Background)", width: "95%" }}
                placeholder="Project title"
                required
                {...register("title")}
              />
            )}
          />
        </div>
        <div className="m-t-16">
          <label className="create-label regularM">Description</label>
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <input
                className="m-t-8 quick-add-task-container create-project-input"
                style={{ background: "var(--App-Background)", width: "95%" }}
                placeholder="Project description"
                {...register("description")}
              />
            )}
          />
        </div>

        {/* <div className="m-t-16" style={{ paddingBottom: 12 }}>
        <label className="create-label regularM">Category</label>

        <div className="w-50">
          <FormSelect
            className="m-t-6"
            control={control}
            label="category"
            id="category"
            value={
              !isEdit
                ? selectedCategory
                  ? {
                      value: selectedCategory?._id,
                      label: selectedCategory?.name,
                    }
                  : null
                : {
                    value: selectedCategory?._id,
                    label: selectedCategory?.name,
                  }
            }
            options={categoriesData?.data?.map((option) => {
              return { value: option._id, label: option.name, ...option };
            })}
            onChange={(e) => {
              if (e) {
                const selectedCategory = categoriesData?.data?.find(
                  (option) => option._id === e.value
                );
                setSelectedCategory(selectedCategory);
              } else {
                remove();
              }
            }}
            name="category"
            isClearable
            // {...register("category")}
          />
        </div>
        
      </div> */}
        <div className="m-t-8 w-100">
          {fields?.map((task, index) => (
            <div key={index} className="justify-space-between pb-1 p-relative">
              <div className="w-100">
                <div className="flex-row align-center justify-between">
                  <div className="flex align-center">
                    <ChevronDownIcon
                      color="white"
                      onClick={() => toggleTaskVisibility(index)}
                    />
                    <span className="boldM white pl-2">
                      {isEdit ? task?.title : "Add task"}
                    </span>
                  </div>
                  <div
                    className="trash-background cursor-pointer"
                    onClick={() => remove(index)}
                  >
                    {/* <TrashIcon color="white" /> */}
                    {/* <DeleteAction /> */}
                    <TrashIcon />
                  </div>
                </div>
                {visibleTasks[index] && (
                  <div className="px-4">
                    <div className="w-100 flex-row">
                      <div className="w-45">
                        <span className="gray-300 mediumM">Task Title</span>
                        <Controller
                          name={`tasksList[${index}].title`}
                          control={control}
                          defaultValue={task?.title || ""}
                          render={({ field }) => (
                            <input
                              className="m-t-8 w-80 quick-add-task-container create-project-input"
                              style={{ background: "var(--App-Background)" }}
                              key={index}
                              // value={task?.title}
                              placeholder="Task Title"
                              {...register(`tasksList[${index}].title`)}
                            />
                          )}
                        />
                      </div>
                      <div className="flex-column w-50 m-t-8">
                        <span className="gray-300 mediumM">Start date</span>

                        <Controller
                          name={`tasksList[${index}].startDate`}
                          control={control}
                          render={({ field }) => (
                            <ReactDatePicker
                              selected={field.value}
                              fixedHeight
                              onChange={(date) => field.onChange(date)}
                              className="m-t-8 w-40 quick-add-task-container create-project-input"
                              placeholderText="Start Date"
                              dateFormat={"dd/MM/yyyy"}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="flex-column w-50 m-t-8">
                      <span className="gray-300 mediumM">End date</span>

                      <Controller
                        name={`tasksList[${index}].endDate`}
                        control={control}
                        render={({ field }) => (
                          <ReactDatePicker
                            selected={field.value}
                            fixedHeight
                            onChange={(date) => field.onChange(date)}
                            className="m-t-8 w-40 quick-add-task-container create-project-input"
                            placeholderText="End Date"
                            dateFormat={"dd/MM/yyyy"}
                          />
                        )}
                      />
                    </div>
                    <div>
                      <div className="w-100">
                        <span className="gray-300 mediumM">
                          Task Description
                        </span>
                        <Controller
                          name={`tasksList[${index}].description`}
                          control={control}
                          defaultValue={task?.description || ""}
                          render={({ field }) => (
                            <textarea
                              className="m-t-8 w-100 quick-add-task-container create-project-input"
                              style={{
                                background: "var(--App-Background)",
                                minHeight: 30,
                                maxHeight: 200,
                                maxWidth: "95%",
                                minWidth: "95%",
                                padding: "8px 16px",
                              }}
                              placeholder="Task description"
                              {...field}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div
        className="p-absolute card-second-wrapper flex-row w-90 justify-space-between"
        style={{
          bottom: -35,
          left: 20,
          width: "95%",
          // right: 24,
        }}
      >
        <Button
          variety="outlined"
          className="m-t-16"
          disabled={!isLastTaskComplete}
          onClick={() => append({ title: "", description: "", date: "" })}
        >
          + Add Task
        </Button>
        <div>
          <Button
            variety="outlined"
            className="m-t-16"
            onClick={() => {
              setModal(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variety="filled"
            className="m-t-16"
            type="submit"
            isLoading={createLoading || updateLoading}
          >
            Submit
          </Button>
        </div>
      </div>
    </form>
  );
};
