import { CalendarIcon } from "@radix-ui/react-icons";
import React, { useState } from "react";
import ReactDatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import "react-select-search/style.css";
import { useDataQuery } from "../../hooks/crud/data.query";
import { useMutationCreate } from "../../hooks/crud/mutation.create";
import { useMutationPatch } from "../../hooks/crud/mutation.patch";
import { API_END_POINTS } from "../../utils/api-endpoint";
import Button from "../Button";
import {
  DatePickerInputComponent,
  CustomInputComponent,
} from "../Input/CustomInputComponent";
import FormSelect from "../Select/FormSelect";
import { notify } from "../Toast/ToastComponent";
import { priorityData } from "./constant";

export const CreateTaskForm = ({
  setModal,
  isEdit,
  selectedData,
  refetch,
  setIsJustUpdated,
  projectData,
  data,
  setData,
}) => {
  const { data: categoriesData } = useDataQuery({
    url: API_END_POINTS.projectCategory,
  });
  const { mutate: createTask, isLoading: createLoading } = useMutationCreate(
    API_END_POINTS.tasks
  );
  const { mutate: updateTask, isLoading: updateLoading } = useMutationPatch(
    `${API_END_POINTS.tasks}/${selectedData?._id}`
  );
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [projectId, setProjectId] = useState(null);
  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    reset,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      title: selectedData?.title,
      date: selectedData?.date,
      description: selectedData?.description,
      priority: selectedData?.priority
        ? {
            label:
              selectedData?.priority?.toLowerCase()?.charAt(0)?.toUpperCase() +
              selectedData?.priority?.toLowerCase().slice(1),

            value: selectedData?.priority?.toLowerCase(),
          }
        : null,
      project: selectedData?.project
        ? {
            label: projectData?.find(
              (data) =>
                data?._id ===
                selectedData?.project?.toLowerCase()?.charAt(0)?.toUpperCase() +
                  selectedData?.project?.toLowerCase().slice(1)
            )?.title,
            value: selectedData?.project?.toLowerCase(),
          }
        : null,
    },

    // resolver: yupResolver(),
  });

  const onSubmit = (data) => {
    isEdit
      ? updateTask(
          { ...data, priority: data?.priority?.value?.toUpperCase() },
          {
            onSuccess(updatedTask) {
              setModal(false);
              // refetch();
              setData((prevData) =>
                prevData.map((task) =>
                  task?._id === updatedTask?.data?._id
                    ? updatedTask?.data
                    : task
                )
              );
              setIsJustUpdated(true);
              notify("Task updated successfully", "success");
            },
          }
        )
      : createTask(
          {
            ...data,
            priority: data?.priority
              ? data?.priority?.value?.toUpperCase()
              : "LOW",
            projectId: projectId,
          },
          {
            onSuccess(newTask) {
              setModal(false);
              // setData((prevData) => [...prevData, newTask]);
              refetch();
              notify("Task created successfully", "success");
            },
          }
        );
  };

  return (
    <div
      style={{
        maxHeight: "500px", // Set this to your desired height
        overflowY: "auto",
      }}
    >
      {/* <CustomInputComponent icon={<CalendarIcon color="#fff" />} /> */}
      <form className="p-12 w-90" onSubmit={handleSubmit(onSubmit)} style={{}}>
        <div className="m-t-24 justify-space-between  p-relative">
          <div className="w-100 flex-row justify-space-between">
            <div className="w-45">
              <span className="gray-300 mediumM">Select Project</span>

              <FormSelect
                className="m-t-6"
                control={control}
                label="project"
                id="project"
                options={projectData?.map((item) => ({
                  label: item.title,
                  value: item._id,
                }))}
                // onChange={(e) => setValue("priority", e.value)}
                onChange={(e) => {
                  if (e?.value) {
                    setProjectId(e?.value);
                  }
                }}
                // value={"Low"}
                name="project"
                placeholder="Select Project"
                isClearable
              />
            </div>
            <div className="w-45">
              <span className="gray-300 mediumM">Select Priority</span>

              <FormSelect
                className="m-t-6"
                control={control}
                label="priority"
                id="priority"
                options={priorityData}
                // onChange={(e) => setValue("priority", e.value)}
                // value={"Low"}
                name="priority"
                placeholder="Select Priority"
              />
            </div>
          </div>
          <div className="w-45">
            <span className="gray-300 mediumM">Task Title</span>
            <Controller
              name={`title`}
              control={control}
              render={({ field }) => (
                <input
                  className="m-t-8 w-80 quick-add-task-container create-project-input"
                  style={{ background: "var(--App-Background)" }}
                  // value={task?.title}
                  placeholder="Task Title"
                  required
                  {...register(`title`)}
                />
              )}
            />
          </div>
          <div>
            <div className="w-100">
              <span className="gray-300 mediumM">Task Description</span>
              <Controller
                name={`description`}
                control={control}
                render={({ field }) => (
                  <textarea
                    className="m-t-8 w-100 quick-add-task-container create-project-input"
                    style={{
                      background: "var(--App-Background)",
                      minHeight: 30,
                      maxHeight: 200,
                      maxWidth: "95%",
                      minWidth: "95%",
                      padding: "8px 16px",
                    }}
                    placeholder="Task description"
                    {...field}
                  />
                )}
              />
            </div>
          </div>
          {/* <div className="flex-column w-50 m-t-8">
            <span className="gray-300 mediumM">Due date</span>

            <Controller
              name={`date`}
              control={control}
              render={({ field }) => (
                <div>
                  <DatePickerInputComponent
                    selected={field.value}
                    onChange={(date) => field.onChange(date)}
                    icon={<CalendarIcon color="#fff" />}
                  />
                </div>
              )}
            />
          </div> */}
          <div className="flex-column w-50 m-t-8">
            <span className="gray-300 mediumM">Start date</span>

            <Controller
              name={`startDate`}
              control={control}
              render={({ field }) => (
                <ReactDatePicker
                  selected={field.value}
                  fixedHeight
                  onChange={(date) => field.onChange(date)}
                  className="m-t-8 w-40 quick-add-task-container create-project-input"
                  placeholderText="Start Date"
                  dateFormat={"dd/MM/yyyy"}
                />
              )}
            />
          </div>
          <div className="flex-column w-50 m-t-8">
            <span className="gray-300 mediumM">End date</span>

            <Controller
              name={`endDate`}
              control={control}
              render={({ field }) => (
                <ReactDatePicker
                  selected={field.value}
                  fixedHeight
                  onChange={(date) => field.onChange(date)}
                  className="m-t-8 w-40 quick-add-task-container create-project-input"
                  placeholderText="End Date"
                  dateFormat={"dd/MM/yyyy"}
                />
              )}
            />
          </div>
        </div>
        <Button
          variety="filled"
          className="m-t-16"
          type="submit"
          isLoading={createLoading || updateLoading}
        >
          {"Save"}
        </Button>
      </form>
    </div>
  );
};
