import React, { useEffect } from "react";
// import { Chart } from "react-google-charts";
import {
  Gantt,
  Task,
  EventOption,
  StylingOption,
  ViewMode,
  DisplayOption,
} from "gantt-task-react";
import "gantt-task-react/dist/index.css";
import { getStartEndDateForProject } from "../../utils/gantt-data";
import { ViewSwitcher } from "./ViewSwitcher";
import "./Gantt.css";
import { API_END_POINTS } from "../../utils/api-endpoint";
import { useDataQuery } from "../../hooks/crud/data.query";
import _ from "lodash";

export const GanttChart = ({ timelineData }) => {
  const [view, setView] = React.useState(ViewMode.Day);

  const [isChecked, setIsChecked] = React.useState(true);
  const {
    data: dashboardData,
    refetch: refetchDashboardData,
    error: dashboardError,
  } = useDataQuery({
    url: API_END_POINTS.dashboard,
    params: {},
  });
  function initTasks(apiResponse) {
    const tasks = apiResponse?.map((task) => {
      return {
        start: new Date(task.start),
        end: new Date(task.end),
        name: task.name,
        id: task.id,
        progress: task.progress || 0, // default to 0 if progress is not provided
        type: task.type,
        project: task.project || null, // default to null if project is not provided
        displayOrder: task.displayOrder,
        hideChildren: false,
      };
    });
    return tasks;
  }
  const [tasks, setTasks] = React.useState(
    initTasks(timelineData ? timelineData : [])
  );

  let columnWidth = 65;
  if (view === ViewMode.Year) {
    columnWidth = 350;
  } else if (view === ViewMode.Month) {
    columnWidth = 300;
  } else if (view === ViewMode.Week) {
    columnWidth = 250;
  }

  // const handleTaskChange = (task) => {
  //   let newTasks = tasks.map((t) => (t.id === task.id ? task : t));
  //   if (task.project) {
  //     const [start, end] = getStartEndDateForProject(newTasks, task.project);
  //     const project =
  //       newTasks[newTasks.findIndex((t) => t.id === task.project)];
  //     if (
  //       project.start.getTime() !== start.getTime() ||
  //       project.end.getTime() !== end.getTime()
  //     ) {
  //       const changedProject = { ...project, start, end };
  //       newTasks = newTasks.map((t) =>
  //         t.id === task.project ? changedProject : t
  //       );
  //     }
  //   }
  //   setTasks(newTasks);
  // };

  const handleTaskDelete = (task) => {
    const conf = window.confirm("Are you sure about " + task.name + " ?");
    if (conf) {
      setTasks(tasks.filter((t) => t.id !== task.id));
    }
    return conf;
  };

  const handleProgressChange = async (task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
  };

  const handleDblClick = (task) => {};

  const handleClick = (task) => {};

  const handleSelect = (task, isSelected) => {};

  const handleExpanderClick = (task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
  };

  return (
    <div className="Wrapper">
      <ViewSwitcher
        onViewModeChange={(viewMode) => setView(viewMode)}
        onViewListChange={setIsChecked}
        isChecked={isChecked}
        viewMode={view}
      />

      {!_.isEmpty(timelineData) ? (
        <Gantt
          tasks={tasks}
          viewMode={view}
          // onDateChange={handleTaskChange}
          onDelete={handleTaskDelete}
          onProgressChange={handleProgressChange}
          onDoubleClick={handleDblClick}
          onClick={handleClick}
          onSelect={handleSelect}
          onExpanderClick={handleExpanderClick}
          listCellWidth={isChecked ? "155px" : ""}
          columnWidth={columnWidth}
          projectBackgroundColor="rgba(68, 115, 218, 0.4)"
          projectProgressColor="#4473DA"
          projectBackgroundSelectedColor="rgba(68, 115, 218, 0.4)"
          projectProgressSelectedColor="#4473DA"
          barProgressColor="#4473DA"
          barBackgroundColor="rgba(68, 115, 218, 0.4)"
          barProgressSelectedColor="#4473DA"
          barBackgroundSelectedColor="rgba(68, 115, 218, 0.4)"
          milestoneBackgroundColor="#4473DA"
          milestoneBackgroundSelectedColor="#4473DA"
          todayColor="var(--App-Background)"
        />
      ) : (
        <span className="white">
          You have not created any projects to be shown on timeline yet
        </span>
      )}
    </div>
  );
};
