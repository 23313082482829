import "../Signup/Signup.css";
import React, { useCallback, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import loginImage from "../../../assets/png/login.png";
import { Logo } from "../../../assets/svg/Logo";
import "react-image-crop/dist/ReactCrop.css";
import ReactCrop from "react-image-crop";
import Button from "../../../components/Button";
import { questionData } from "./DATA";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Modal from "../../../components/Model/Model";
import { QuestionCompleted } from "../../../assets/svg/QuestionCompleted";
import { APP_ROUTES } from "../../../utils/app-routes";
import { API_END_POINTS } from "../../../utils/api-endpoint";
import { useMutationCreate } from "../../../hooks/crud/mutation.create";

const OnboardingQuestion = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [value, setValue] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    reset,
  } = useForm({});
  const { mutate: submitData } = useMutationCreate(API_END_POINTS.onboarding);

  const onSubmit = (data) => {
    if (page === questionData.length - 1) {
      setIsModalVisible(true);
    } else {
      submitData({
        questionLabel: questionData?.[page]?.label,
        answer: data[questionData?.[page]?.name],
      });
      setPage(page + 1);
      reset();
    }
  };

  return (
    <>
      <div className="w-full flex">
        <div className="login-banner w-50">
          <img className="w-full h-screen" src={loginImage} alt="Login"></img>
        </div>

        <div className="box w-1/2">
          <div className="form-side">
            <div
              style={{
                height: "3.125rem;",
                width: "11.53rem",
                marginBottom: "3.12rem",
              }}
            >
              <Logo />
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div>
                <label className="question-label">
                  {questionData?.[page]?.label}
                </label>
                <Controller
                  name="username"
                  control={control}
                  render={({ field }) => (
                    <input
                      className="w-full mt-4"
                      placeholder={questionData?.[page]?.placeholder}
                      type="text"
                      onChange={(e) => setValue(e.target.value)}
                      {...register(questionData?.[page]?.name)}
                      required
                    />
                  )}
                />
                <p
                  style={{
                    color: "var(--App-Red)",
                  }}
                >
                  {errors.username?.message}
                </p>
              </div>
              <Button type="submit" variety="primary" className="w-100 mt-4">
                Next
              </Button>
            </form>
          </div>
          <Modal
            show={isModalVisible}
            title="Thank you"
            titleIcon={<QuestionCompleted />}
            onClose={() => setIsModalVisible(false)}
          >
            <div className="text-align-center">
              <span className="white p-24">
                Thank you for submitting your answers!
              </span>
            </div>
            <div className="justify-center align-center m-t-24">
              <Button
                variety="primary"
                onClick={() => navigate(APP_ROUTES.root)}
                className="w-60"
              >
                Go Home
              </Button>
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
};
export default OnboardingQuestion;
