import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMutationCreate } from "../../hooks/crud/mutation.create";
import { API_END_POINTS } from "../../utils/api-endpoint";
import { APP_ROUTES } from "../../utils/app-routes";

const InstagramCallback = () => {
  const navigate = useNavigate();

  const { mutate: storeInstagramToken } = useMutationCreate(
    API_END_POINTS.storeInstagramToken
  );
  const storeToken = async (access_token) => {
    try {
      const res = await storeInstagramToken({ access_token: access_token });
      if (res) {
        navigate(APP_ROUTES.social);
      }
      return res;
      // .then((res) => Notification("Success", res?.message, "success"))
      // .catch((err) => {
      //     console.log("err", err);
      // });
      // setUserId(id);
    } catch (err) {}
  };
  useEffect(() => {
    const url = new URL(window.location);
    const splittedValue = url?.hash?.split("=");
    if (splittedValue) {
      // const authorizationCode = searchParams.get("token");
      const access_token = splittedValue?.[1]?.split("&")[0];
      storeToken(access_token);
    }
  }, []);

  return (
    <div>
      <h3 className="white">Processing...</h3>
      {/* You can show a loading spinner or a message while the authorization process is in progress */}
    </div>
  );
};

export default InstagramCallback;
