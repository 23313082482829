export const questionData = [
  {
    id: 1,
    label: "What's your legal name?",
    name: "legalName",

    placeholder: "Please enter your legal name",
  },
  {
    id: 2,
    label: "What's your artist name?",
    name: "artistName",

    placeholder: "Please enter your artist name",
  },

  {
    id: 3,
    label: "When do you plan to release next?",
    name: "nextRelease",

    placeholder: "Please enter your next release",
  },
  {
    id: 4,
    label: "How many songs do you want to release this year?",
    name: "numOfSongs",

    placeholder:
      "Please enter the number of songs you want to release this year",
  },
  {
    id: 5,
    label: "How many followers do you want to grow by this year?",
    name: "numOfFollowers",

    placeholder: "Please enter the number of target followers",
  },
  {
    id: 6,
    label: "Are you able to make music start to finish on your own?",
    name: "musicStart",

    placeholder: "Yes/No",
  },
  {
    id: 7,
    label: "Are you able to make video content on your own?",
    name: "videoContentOnOwn",

    placeholder: "Please enter if you can make video content on your own",
  },
  {
    id: 8,
    label: "Who are your influences?",
    name: "influence",

    placeholder: "Please enter your influences",
  },
  {
    id: 9,
    label: "What style(s) of music do you make",
    name: "musicStyles",

    placeholder: "Please enter the style(s) of music you make",
  },
  {
    id: 10,
    label: "How would you describe your visual aesthetic",
    name: "visualAesthetic",

    placeholder: "Please enter your visual aesthetic",
  },
  {
    id: 11,
    label: "What were the defining moments that lead you to making music?",
    name: "definingMoments",

    placeholder:
      "Please enter the defining moments that lead you to making music",
  },
  {
    id: 12,
    label: "Who is your ideal fan?",
    name: "idealFan",

    placeholder: "Please enter your ideal fan",
  },
  {
    id: 13,
    label: "What messages do you want to share with your fans?",
    name: "messageSharingWithFans",

    placeholder: "Please enter the messages you want to share with your fans",
  },
];
