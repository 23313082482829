import { CalendarIcon } from "../../assets/svg/CalendarIcon";
import { CheckCircle } from "../../assets/svg/CheckCircle";
import { LocationIcon } from "../../assets/svg/LocationIcon";
import { AnimatedList } from "../magicui/animated-list";
import "./ListView.css";
import React, { useEffect, useState } from "react";

export const ListViewComponent = ({
  title,
  date,
  duration,
  onClick,
  handleMark,
  completed,
  projectData,
  data,
  onClickComplete = () => {},
}) => {
  const [isFirstRender, setIsFirstRender] = useState(false);

  useEffect(() => {
    const hasLoadedBefore = sessionStorage.getItem("hasLoadedBefore");

    if (!hasLoadedBefore) {
      setIsFirstRender(true);
      setTimeout(() => {
        sessionStorage.setItem("hasLoadedBefore", "true");
      }, 500);
    }
  }, [isFirstRender]);

  const isPastDate = new Date(date) < new Date();
  const dateClass = isPastDate ? "red" : "gray-300";

  const ListComponent = isFirstRender ? AnimatedList : "div";

  const selectedProject = projectData?.filter(
    (item) => data?.project === item?._id
  );

  return (
    <ListComponent>
      <div className="list-view-wrapper p-8 m-8 cursor-pointer relative">
        <div onClick={onClick} className="flex-row justify-space-between">
          <span className="regularS white">
            {selectedProject?.[0]
              ? selectedProject?.[0]?.title + " - " + title
              : title}
          </span>
        </div>
        <div
          className="flex-row align-center absolute right-0"
          onClick={(e) => {
            e.stopPropagation();
            onClickComplete();
          }}
        >
          <CheckCircle />
          <span className="white p-4">
            {completed ? "Completed" : "Incomplete"}
          </span>
        </div>
        <div className="flex-row align-center">
          <CalendarIcon
            color={isPastDate ? "#EF4870" : "var(--Genesis-Gray-300)"}
          />{" "}
          <span className={`regularS p-4 ${dateClass}`}>{date}</span>
          <span>{duration}</span>
        </div>
      </div>
    </ListComponent>
  );
};

export const EventListViewComponent = ({
  title,
  date,
  duration,
  description,
  location,
  item,
  onClick,
}) => {
  const [isFirstRender, setIsFirstRender] = useState(false);

  useEffect(() => {
    const hasLoadedBeforeEvent = sessionStorage.getItem("hasLoadedBeforeEvent");
    if (!hasLoadedBeforeEvent) {
      setIsFirstRender(true);
      setTimeout(() => {
        sessionStorage.setItem("hasLoadedBeforeEvent", "true");
      }, 500);
    }
  }, [isFirstRender]);
  const EventComponent = isFirstRender ? AnimatedList : "div";

  const truncateText = (text, maxLength) => {
    if (!text) return "";
    return text.length > maxLength
      ? text.substring(0, maxLength) + "..."
      : text;
  };
  return (
    <EventComponent>
      <div
        className="list-view-wrapper p-8 m-8 cursor-pointer"
        onClick={onClick}
      >
        <div className="flex-row justify-space-between">
          <span className="regularS white">{title}</span>
          <div className="flex-row align-center w-40">
            <div className="flex-row align-center">
              <CalendarIcon /> <span className="regularS p-4 red">{date}</span>
            </div>
            <div className="flex-row align-center">
              <LocationIcon />
              <span className="regularS p-4 white">{location}</span>
            </div>
            <span>{duration}</span>
          </div>
        </div>
        <div>
          <span className="white">{truncateText(description, 49)}</span>
        </div>
      </div>
    </EventComponent>
  );
};
