import { yupResolver } from "@hookform/resolvers/yup";
import { useGoogleLogin } from "@react-oauth/google";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import * as yup from "yup";
import loginImage from "../../../assets/png/login.png";
import {
  EyeCloseOutlined,
  EyeOpenOutlined,
} from "../../../assets/svg/EyeOutlined";
import { GoogleIcon } from "../../../assets/svg/GoogleIcon";
import { Logo } from "../../../assets/svg/Logo";
import Button from "../../../components/Button";
import { useMutationCreate } from "../../../hooks/crud/mutation.create";
import { API_END_POINTS } from "../../../utils/api-endpoint";
import { APP_ROUTES } from "../../../utils/app-routes";
import {
  GENESIS_USER_EMAIL,
  GENESIS_USER_SIGNUP,
  GENESIS_USER_TOKEN,
  USER_DETAILS,
} from "../../../utils/constants";
import { saveState } from "../../../utils/storageUtil";
import "./LoginPage.css";
import { notify } from "../../../components/Toast/ToastComponent";

const Login = () => {
  const navigate = useNavigate();
  const [headers, setHeaders] = useState({}); //[1
  const { mutate: googleAuth } = useMutationCreate(
    API_END_POINTS.googleSignIn,
    headers
  );

  const login = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      setHeaders({ Authorization: `Bearer ${codeResponse.access_token}` });
      setTimeout(() => {
        googleAuth(
          {},
          {
            onSuccess(res) {
              if (
                res?.data?.isFirstTimeSocialLogin ||
                !res?.data?.isPaymentVerified
              ) {
                localStorage.setItem(USER_DETAILS, JSON.stringify(res));
                localStorage.setItem(GENESIS_USER_TOKEN, res?.token);
                saveState(GENESIS_USER_SIGNUP, true);
                saveState(GENESIS_USER_EMAIL, res?.data?.emailAddress);
                navigate(APP_ROUTES.paymentPlan, {
                  email: res?.data?.emailAddress,
                  token: res?.token,
                });
                notify("Please complete your payment to continue", "success");
              } else {
                saveState(GENESIS_USER_TOKEN, res?.token);
                saveState(GENESIS_USER_SIGNUP, false);
                saveState(GENESIS_USER_EMAIL, res?.data?.emailAddress);
                navigate(APP_ROUTES.root);
                localStorage.setItem(USER_DETAILS, JSON.stringify(res));
                notify(res?.message, "success");
              }
            },
          },
          {
            onError(err) {
              notify(err?.message, "error");
              console.log("err", err);
            },
          }
        );
      }, [400]);
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  return (
    <>
      <div className="w-full flex">
        <div className="login-banner w-50">
          <img className="w-full h-screen" src={loginImage} alt="Login"></img>
        </div>

        <div className="box">
          <div className="form-side">
            <div
              style={{
                height: "3.125rem;",
                width: "11.53rem",
                marginBottom: "3.12rem",
              }}
            >
              <Logo />
            </div>
            <div>
              <span className="h4">Welcome back</span>
              <br />
              <span className="regularS gray-300">
                Continue with Google or enter your details
              </span>
              {/* <GoogleLogin
                onSuccess={(credentialResponse) => {
                  console.log("response", credentialResponse);
                }}
              /> */}
              <Button
                type="submit"
                variety="secondary"
                className="flex-row m-t-24"
                height={"50px"}
                onClick={login}
              >
                <GoogleIcon />
                Continue with Google
              </Button>
            </div>
            <div className="flex-row m-t-24 justify-space-between align-center">
              <hr className="solid gray-500 w-25" />
              <span className="gray-300 regularXS">
                or log in with your email
              </span>
              <hr className="solid w-25" />
            </div>
            <div className="w-full">
              <LoginForm navigate={navigate} />
            </div>
            <div
              className="text-align-center cursor-pointer m-t-12"
              onClick={() => navigate(APP_ROUTES.signup)}
            >
              <span className="gray-300">Don't have an account?</span>
              <span className="green-400 semiboldM"> Sign Up</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;

const schema = yup.object().shape({
  email: yup.string().required("Email is required"),
  password: yup.string().required("Password is required"),
});

const LoginForm = ({ navigate }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const {
    mutate: loginUserDetails,
    isLoading,
    error,
  } = useMutationCreate(API_END_POINTS.login);
  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    loginUserDetails(data, {
      onSuccess(res) {
        navigate(APP_ROUTES.root);
        localStorage.setItem(USER_DETAILS, JSON.stringify(res));
        notify(res?.message, "success");
        if (data.rememberMe) {
          localStorage.setItem("rememberedEmail", data.email);
          localStorage.setItem("rememberedPassword", data.password);
        } else {
          localStorage.removeItem("rememberedEmail");
          localStorage.removeItem("rememberedPassword");
        }
      },
      onError: (err) => {
        console.log("err in login", err);
        notify(err?.error?.message, "error");
      },
    });
  };

  useEffect(() => {
    const rememberedEmail = localStorage.getItem("rememberedEmail");
    const rememberedPassword = localStorage.getItem("rememberedPassword");

    if (rememberedEmail && rememberedPassword) {
      setValue("email", rememberedEmail);
      setValue("password", rememberedPassword);
      setValue("rememberMe", true);
    }
  }, [setValue]);

  return (
    <form className="m-t-24 w-full" onSubmit={handleSubmit(onSubmit)}>
      <div>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <input
              className="w-full"
              placeholder="Email"
              type="email"
              {...register("email")}
            />
          )}
        />
        <p
          style={{
            color: "var(--App-Red)",
          }}
        >
          {errors.email?.message}
        </p>
      </div>

      <div>
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <div style={{ position: "relative", marginTop: 24, width: "100%" }}>
              <input
                className="w-full"
                placeholder="Password"
                type={showPassword ? "text" : "password"}
                {...field}
                {...register("password")}
              />
              <span
                style={{
                  position: "absolute",
                  right: "15px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
                onClick={() => setShowPassword(!showPassword)}
              >
                {!showPassword ? <EyeOpenOutlined /> : <EyeCloseOutlined />}{" "}
                {/* Change the icon based on the state variable */}
              </span>
            </div>
          )}
        />
        <p
          style={{
            color: "var(--App-Red)",
          }}
        >
          {errors.password?.message}
        </p>
      </div>

      <div className="flex-row justify-space-between align-center mt-4">
        <label className="left white align-center">
          <input type="checkbox" {...register("rememberMe")} />
          <span className="regularS">Remember me</span>
        </label>

        <a className="right green-400 mediumS" href={APP_ROUTES.forgotPassword}>
          Forgot Password?
        </a>
      </div>

      <Button
        type="submit"
        variety="filled"
        className="primary-button w-100 black m-t-24"
        height={"56px"}
        isLoading={isLoading}
      >
        Continue
      </Button>
    </form>
  );
};
