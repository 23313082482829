import moment from "moment";
import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { ModalViewDetails } from "../Dashboard/ModalView";
import Modal from "../Modal/Modal";
import CustomToolbar from "../Toolbar/CustomToolbar";
import "./MyCalendar.css";
import { ListViewComponent } from "../ListView/ListViewComponent";

const localizer = momentLocalizer(moment);

const MyCalendar = (props) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [view, setView] = useState("");
  const [isTodaySelected, setIsTodaySelected] = useState(false); // New state variable
  const events = props?.isTask
    ? props?.data?.map((task) => ({
        start: new Date(task?.date),
        end: new Date(task?.date),
        title: task.title,
        _id: task._id,
      }))
    : props?.data?.map((task) => ({
        start: new Date(task?.startDate),
        end: new Date(task?.endDate),
        title: task.title,
        _id: task._id,
      }));
  const [data, setData] = useState([]);

  const handleEventDrop = ({ event, start, end, allDay }) => {
    const idx = events.indexOf(event);
    const updatedEvent = { ...event, start, end, allDay };

    const nextEvents = [...events];
    nextEvents.splice(idx, 1, updatedEvent);

    props.onEventChange(nextEvents);
  };
  const handleEventResize = ({ event, start, end }) => {
    const idx = events.indexOf(event);
    const updatedEvent = { ...event, start, end };

    const nextEvents = [...events];
    nextEvents.splice(idx, 1, updatedEvent);

    props.onEventChange(nextEvents);
  };

  // Function to handle date selection
  const handleSelect = ({ start, end }) => {
    const today = moment().startOf("day");
    if (moment(start).isSame(today, "d")) {
      setIsTodaySelected(true);
    } else {
      setIsTodaySelected(false);
    }
  };

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  return (
    <div>
      {isTodaySelected ? (
        <ListViewComponent />
      ) : (
        <Calendar
          onSelectSlot={handleSelect}
          className={
            props?.isTask && (view === "day" || view === "week")
              ? "hide-time-gutter"
              : ""
          }
          localizer={localizer}
          defaultDate={new Date()}
          defaultView="month"
          events={events}
          style={{ height: "100vh", background: "transparent" }}
          onSelectEvent={(event) => {
            setSelectedEvent(event);
            setIsPopupOpen(true);
            // props?.setSelectedData(event);
          }}
          onView={setView}
          components={{
            toolbar: CustomToolbar,
          }}
          onEventDrop={handleEventDrop}
          onEventResize={handleEventResize}
          resizable
        />
      )}
      <Modal
        isOpen={isPopupOpen}
        // className={"card-app-background align-center"}
        onClose={() => setIsPopupOpen(false)}
        title={"Task Details"}
        handleDeleteTask={props.deleteTask}
        isEditButton={true}
        onEditClick={() => {
          props.setIsModalOpen(false);
          props.setIsCreateModalOpen(true);
          props.setModalTitle("Edit " + props.selectedTab);
          props.setIsEdit(true);
        }}
      >
        <ModalViewDetails selectedEvent={selectedEvent} />
      </Modal>
    </div>
  );
};

export default MyCalendar;
